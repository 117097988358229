<template>
  <div class="nvx-app-container">
    <nvx-button-view>

      <template v-slot:content>
        <router-link to="/account">
          <chevron :iconDirection="'left'" />
        </router-link>

        <h2 class="sdc-title"> {{ $t("delete_your_account") | capitalize }} </h2>

        <p class="sdc-description sdc-secondary-text"> {{ $t("account_delete_description") }} </p>

        <nvx-section>
          <p> {{ $t("password_close_account") | capitalize }} </p>

          <div class="sdc-field sdc-password-field">
            <input 
              v-model="password" 
              :type="show ? 'text' : 'password'" 
              class="sdc-password-input" 
              inputmode="password"
              :placeholder="$t('password') | capitalize"
              :class="{ 'invalid': !passwordValidation }" 
            />
            <eyes :open=!show @click.native="show = !show"/>
          </div>

          <small v-if="!passwordValidation" class="invalid-text">
            {{ $t("incorrect_password") | capitalize }}
          </small>
        </nvx-section>
      </template>

      <template v-slot:buttons>
        <button 
          @click="deleteValidation = !deleteValidation"
          class="sdc-button-checkbox"
          :class="{ 'isChecked': deleteValidation }"
        >
          <p class="sdc-secondary-text sdc-button-checkbox-text"> {{ $t("account_delete_validation") | capitalize }} </p>
          <check v-if="deleteValidation" />
          <no-select v-else />
        </button>

        <button class="sdc-button" @click="deleteAccount()" block variant="primary" :disabled="!passwordValidation || !deleteValidation">
          {{ $t("finish") | capitalize }}
        </button>
      </template>

    </nvx-button-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Chevron from '../svg/chevron.vue';
import Check from "../svg/check.vue";
import NoSelect from "../svg/noselect.vue";
import Eyes from "../svg/eyes.vue";
import NvxSection from "@/components/ui/NvxSection.vue";
import NvxButtonView from "@/components/ui/NvxButtonView.vue";

export default {
  components: { 
    Chevron,
    Check, 
    NoSelect,
    Eyes,
    NvxButtonView,
    NvxSection,
  },
  name: "AccountDelete",

  data() {
    return {
      password: "",
      show: false,
      passwordValidation: true,
      newsletters: false,
      deleteValidation: false,
    };
  },

  computed: {
    ...mapGetters("users", ["getUser", "getUserName"]),
    user_data() {
      return { email: this.getUserName, password: this.password };
    },
  },

  watch: {
    password() {
      this.passwordValidation = true;
    },
  },

  methods: {
    ...mapActions("users", ["setToken", "deleteUserData", "fetchUserData"]),
    async deleteAccount() {
      let response = await this.deleteUserData(this.user_data);
      this.passwordValidation = response != "error";
      if (this.passwordValidation) {
        window.location.href = '/logout/';
      }
    },
  },

  async mounted() {
    window.zE("webWidget", "hide");
  },

  async created() {
    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      this.setToken(token);
    }
    this.fetchUserData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";
</style>